html {
  height: 100%;
}

body {
  background-color: #fff;
  height: 100vh;
}

/*# sourceMappingURL=index.390819d1.css.map */
